<template>
  <div class="page">
    <!--  导航  -->
    <nav-bar PitchOn=""></nav-bar>
    <!--    主体-->
    <div class="main-info">
      <div class="info-left">
        <div class="guide-info">
          <el-breadcrumb :separator-icon="ArrowRight">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>搜索结果</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="article-list">
          <div class="list" v-for="(item,index) in articleList"
               @click="onArticle(item)"
               :key="index">
            <div class="picture">
              <el-image class="el-img" :src="item.img"></el-image>
            </div>
            <div class="item">
              <div class="title">
                <span>{{item.title}}</span>
              </div>
              <div class="desc">
                <span>{{item.describe}}</span>
              </div>
              <div class="date-view">
                <div class="view">
                  <span class="iconfont icon-shijian"></span>
                  <span>{{parseTime(item.create_time,'{y}-{m}-{d}')}}</span>
                </div>
                <div class="view">
                  <span class="iconfont icon-liulan"></span>
                  <span>{{item.page_view}}人观看</span>
                </div>
                <div class="view">
                  <span class="iconfont icon-pinglun"></span>
                  <span>{{item.comment_num}}人吐槽</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <el-empty description="无数据" v-show="articleList.length === 0"></el-empty>
        <div class="pagination">
          <el-pagination background layout="prev, pager, next"
                         @current-change="changePage"
                         :page-size="20"
                         :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getCurrentInstance, onMounted, reactive, toRefs} from "vue";
import {useRoute, useRouter} from "vue-router";
import {ArticleList} from "../../api/api";
import NavBar from "../../components/NavBar/NavBar";

export default {
  name: "SearchResult",
  components: { NavBar},
  setup(){
    let { proxy } = getCurrentInstance();
    const route = useRoute()
    const router = useRouter()
    const data = reactive({
      articleList: [],
      parseTime: '',
      keyword: route.query.keyword||'',
      // 页码
      page: 1,
      total: 0,
      /**
       * 获取数据
       */
      getData(){
        ArticleList({
          keyword: data.keyword,
          page: data.page
        }).then(res =>{
          data.articleList = res.data.result;
          data.total = res.data.total;
        })
      },
      /**
       * 页码改变
       * @param page
       */
      changePage(page){
        data.page = page;
        data.getData();
      },
      /**
       * 文章点击
       * @param item
       */
      onArticle(item){
        router.push({path: '/ArticleDetails',query: {article_id: item.article_id}})
      }
    })
    onMounted(() => {
      data.parseTime = proxy.util.parseTime;
      data.getData();
    })
    return {
      ...toRefs(data),
    }
  }
}
</script>

<style scoped lang="scss">
  @import "SearchResult.scss";
</style>
